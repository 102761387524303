import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="NucleusUI" link="https://mannyyang.github.io/NucleusUI/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  The component library or design system I built that is used within Nativo's 
  client products.
    </ProjectCard>
    <ProjectCard title="Solunar.io" link="http://solunar.io" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  See fishing predictions based off the solunar theory for the week.
    </ProjectCard>
    <ProjectCard title="Ekko Church" link="https://ekkochurch.com/" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Rebuilt Ekko Church's main website. Custom theme creation for wordpress.
    </ProjectCard>
    <ProjectCard title="Lotecom" link="https://mannyyang.github.io/lotecom/" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Low-tech Communication. A drag and drop application to set communication cards to print out. Useful for
  speech therapy or nurses to communicate with non-verbal patients.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      