import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I'm a Software Engineer for `}<a parentName="p" {...{
          "href": "https://nativo.com"
        }}>{`Nativo`}</a>{` in Los Angeles, CA.`}</p>
    </blockquote>
    <p>{`I enjoy tinkering in the land of web development and data driven user interfaces,
effects, animations and creating intuitive, dynamic user experiences. `}</p>
    <p>{`Let's make something special.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      